import React from "react"
import { graphql } from "gatsby"
import { Box } from "rebass"
import { gridUnit } from "../theme"

import Layout from "../components/layout"
import { Link, BlogH4 } from "../components/styles"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle} description={siteDescription}>
      <SEO title="All posts" />
      <Box
        as="main"
        mx={gridUnit(3)}
        mb={4}
        mt={4}
        maxWidth="800px"
        minWidth="300px"
      >
        {posts.map(({ node }, i) => {
          const title = node.frontmatter.title || node.fields.slug
          return <Post node={node} title={title} key={i} />
        })}
      </Box>
    </Layout>
  )
}

const Post = ({ node, title }) => (
  <Box as="article" maxWidth={800} minWidth={300} my={3} key={node.fields.slug}>
    <header>
      <BlogH4 mb={1} mt={3}>
        <Link to={node.fields.slug}>{title}</Link>
      </BlogH4>
    </header>
  </Box>
)

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            posttype
          }
        }
      }
    }
  }
`
